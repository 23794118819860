"use client"
import { useEffect, useRef, useState } from "react";
import type { LottiePlayer } from "lottie-web";
import { useRouter } from "next/navigation";

export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string }
    reset: () => void
}) {

    useEffect(() => {
        // Log the error to an error reporting service
        console.error(error)
    }, [error])

    const lottieRef = useRef<HTMLDivElement>(null)
    const [lottie, setLottie] = useState<LottiePlayer | null>(null)
    const router = useRouter();

    useEffect(() => {
        import('lottie-web').then((Lottie) => setLottie(Lottie.default))
    }, [])

    useEffect(() => {
        if (lottie && lottieRef.current) {
            const animation = lottie.loadAnimation({
                container: lottieRef.current,
                renderer: "svg",
                loop: true,
                name: `error-500`,
                autoplay: true,
                animationData: require(`@/misc/lottie/error-500.json`)
            })
            return () => animation.destroy();
        }
    }, [lottie]);

    return (
        <div className="bg-white dark:bg-neutral-900">
            <div className='mx-auto container h-screen box-border font-sans text-black dark:text-gray-200 pt-32 pb-14 flex flex-col items-center'>
                <div className="w-full h-full mx-auto"
                    ref={lottieRef}
                />
                <button onClick={
                    // Attempt to recover by trying to re-render the segment
                    () => reset()
                } className="transition hover:text-teal-500">
                    Try again
                </button>
            </div>
        </div>
    )
}